import { Grid } from 'semantic-ui-react';
import gameNameText from '../hooks/useProductText';

const month = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const d = new Date();
const n = d.getMonth();

const TopText = () => {
  return (
    <Grid
      className="top-text-container"
      container
      textAlign="center"
      columns={3}
    >
      <Grid.Row className="top-text">
        {window.location.pathname.includes('/slots/') ? (
          <h2>
            Best UK Slot Sites To Play{' '}
            <h2 className="gameText">{gameNameText()}</h2>
          </h2>
        ) : window.location.pathname.includes('/top-uk-casinos/') ? (
          <h2>Play At The Top UK Casinos</h2>
        ) : window.location.pathname.includes('/best-uk-slot-sites/') ? (
          <h2>The Best Slot Sites In The UK</h2>
        ) : window.location.pathname.includes('/welcome-bonuses/') ? (
          <h2>Get The Best Bonus Offers Available</h2>
        ) : window.location.pathname.includes('/premium-casinos/') ? (
          <h2>Casinos That Know How To Treat Their Customers </h2>
        ) : (
          <h2>
            Our Top UK Slot Sites for{' '}
            <h2 className="gameText">{month[n]} {d.getFullYear()}</h2>{' '}
          </h2>
        )}
      </Grid.Row>
    </Grid>
  );
};
export default TopText;
