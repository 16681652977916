import Route from './components/Route';
import MainPage from './pages/Main';
import SlotGames from './pages/SlotGames';
import TopUKCasinos from './pages/TopUKCasinos';
import BestUKSlotSites from './pages/BestUKSlotSites';
import WelcomeBonuses from './pages/WelcomeBonuses';
import PremiumCasinos from './pages/PremiumCasinos';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Contact from './pages/Contact';
import Cookies from './pages/Cookies';
import CookieConsent from 'react-cookie-consent';
import Meta from './components/Meta';

const App = () => {
  const pathName = window.location.pathname;
  const searchParams = window.location.search;
  return (
    <div>
      <Route path={pathName}>
        {pathName == '/' ? (
          <MainPage />
        ) : pathName.includes('/slots/') ? (
          <SlotGames searchParams={searchParams} />
        ) : pathName.includes('/privacy/') ? (
          <Privacy />
        ) : pathName.includes('/terms/') ? (
          <Terms />
        ) : pathName.includes('/contact/') ? (
          <Contact />
        ) : pathName.includes('/cookies/') ? (
          <Cookies />
        ) : pathName.includes('/top-uk-casinos/') ? (
          <TopUKCasinos />
        ) : pathName.includes('/best-uk-slot-sites/') ? (
          <BestUKSlotSites />
        ) : pathName.includes('/welcome-bonuses/') ? (
          <WelcomeBonuses />
        ) : pathName.includes('/premium-casinos/') ? (
          <PremiumCasinos />
        ) : (
          <MainPage />
        )}
      </Route>
      <Meta />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="sgoConsent"
        style={{ 
          background: "#1a1a1a",
          fontSize: "14px",
          padding: "1rem"
        }}
        buttonStyle={{ 
          background: "#4CAF50",
          color: "white",
          fontSize: "14px",
          padding: "8px 16px",
          borderRadius: "4px"
        }}
        expires={150}
      >
        This website uses cookies to enhance your experience. By continuing to use this site, you consent to our use of cookies.{" "}
        <a href="/cookies/" style={{ color: "#4CAF50" }}>
          Learn more
        </a>
      </CookieConsent>
    </div>
  );
};

export default App;
