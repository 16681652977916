import './Listing.css';
import Logo from './Logo';
import Like from './Like';
import { Grid, Button, Divider } from 'semantic-ui-react';
import Link from './Link';
import React, { useState, useEffect } from 'react';
import generateString from '../hooks/useUserId';

const Listing = ({ brands }) => {
  const tableTitle = () => {
    return (
      <Grid
        className="title"
        container
        textAlign="center"
        columns={5}
        divided="vertically"
      >
        <Grid.Row className="title-row" color="black" only="computer">
          <Grid.Column>Brand</Grid.Column>
          <Grid.Column>Bonus</Grid.Column>
          <Grid.Column>Feature</Grid.Column>
          <Grid.Column>Rating</Grid.Column>
          <Grid.Column>Visit Site</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  const renderedBrands = brands.map(
    ({ terms, logo, offer, comment, link, linkName, rating, star }) => {
      return (
        <Grid
          container
          doubling
          textAlign="center"
          columns={5}
          verticalAlign="middle"
          divided
          inverted
        >
          <Grid.Row className="listing" color="black">
            <Grid.Column>
              <Link href={`${link}`}>
                <Logo logo={logo} />
              </Link>
            </Grid.Column>

            <Grid.Column only="computer" className="offer">
              <Link href={`${link}`}>
                {offer}
                <div className="terms">{terms}</div>
              </Link>
            </Grid.Column>
            <Grid.Column only="mobile tablet" className="offer">
              {offer}
            </Grid.Column>
            <Grid.Column className="comment">{comment}</Grid.Column>
            <Grid.Column only="mobile">
              <Link href={`${link}${generateString(44)}`}>
                <Button fluid color={'blue'}>
                  {'Get Bonus'}
                </Button>
              </Link>
            </Grid.Column>
            <Grid.Column only="mobile">
              <Like rating={rating} star={star} />
            </Grid.Column>

            <Grid.Column only="computer tablet">
              <Like rating={rating} star={star} />
            </Grid.Column>

            <Grid.Column only="computer tablet">
              <Link href={`${link}`}>
                <Button fluid color="blue">
                  {'Get Bonus'}
                </Button>
              </Link>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row only="mobile tablet">
            <div className="terms">{terms}</div>
            <Divider clearing />
          </Grid.Row>
        </Grid>
      );
    }
  );

  return (
    <div>
      <div className="table-title">{tableTitle()}</div>
      <div className="grid-listing">{renderedBrands}</div>
    </div>
  );
};

export default Listing;
